<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12" md="6" lg="6">
        <h2 v-html="$t('messages_inbox_title')"></h2>
      </v-col>
    </v-row>
    <div>
      <v-row class="row spacer-md">
        <v-col xs="12" md="6" lg="2">
          <search-message ref="search-message" @search="search" @keyup.enter="search" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn color="primary" @click="search"><span v-html="$t('messages_search')" /></v-btn>
          <v-btn color="secondary" @click="reset"><span v-html="$t('messages_reset')" /></v-btn>
        </v-col>
        <v-col cols="3">
          <v-menu offset-y v-model="dropDown">
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props">Downloads</v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in ['Resend Selection', 'Download Selection', 'Delete not read of Selection']"
                :key="index"
                @click="handleClick(item)"
              >
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
      <v-chip v-if="unreadMessagesNr !== 0" class="ma-2" variant="flat" v-html="unreadMessagesBadge"></v-chip>
      <v-data-table-server
        :multi-sort="true"
        :items="items"
        :headers="fields"
        :loading="loading"
        :items-length="totalElements"
        :items-per-page-options="$itemsPerPageOptions"
        @update:itemsPerPage="routerPushSize"
        @update:page="routerPushPage"
        @update:sort-by="routerPushDTSort"
        :sort-by="toDTSort(query.sort)"
        :page="getDTPageFromRoute(query.page)"
        :items-per-page="query.size"
        @click:row="handleClickOnRow"
        dense
      >
        <template v-slot:[`item.priority`]="{ item }">
          <span v-if="item.priority === true">
            <v-icon dbmblueprimary="">mdi-star</v-icon>
          </span>
          <span v-else> <v-icon dbmpassivgrey="">mdi-star-outline</v-icon> </span>
        </template>
        <template v-slot:[`item.sent`]="{ item }">
          {{ $formatChDateTime(item.sent) }}
        </template>
        <template v-slot:[`item.attachments`]="{ item }">
          <div v-if="item.attachments != null">
            <v-tooltip bottom>
              <template v-slot:activator="{ props }">
                <span v-bind="props">
                  <v-icon dbmblueprimary="" @click="downloadAttachment(item.uuid, item.attachmentIds, item.attachments)" :loading="loadingAttachment"
                    >mdi-attachment</v-icon
                  ></span
                >
              </template>
              <span>{{ item.attachments.toString() }}</span>
            </v-tooltip>
          </div>
        </template>
        <template v-slot:[`item.read`]="{ item }">
          {{ $formatChDateTime(item.read) }}
        </template>
        <template v-slot:[`item.tags`]="{ item }">
          {{ JSON.stringify(item.tags) }}
        </template>
        <!--        <template v-slot:[`item.read`]="{ item }">-->
        <!--          {{ $formatChDateTime(item.read).substr(0, 10) }}-->
        <!--        </template>-->
        <template v-slot:[`item.subject`]="{ item }">
          <div v-if="item.subject.length > messageTitlePreviewLength">
            <v-tooltip bottom>
              <template v-slot:activator="{ props }">
                <span v-bind="props">{{ item.subject.substr(0, messageTitlePreviewLength) }}</span>
              </template>
              <span> {{ item.subject }} </span>
            </v-tooltip>
          </div>
          <div v-else>
            {{ item.subject }}
          </div>
        </template>
      </v-data-table-server>
    </div>
    <message-detail :message="messageDetail" :showDialog="showDialog" @closeDialog="closeDialog"></message-detail>
  </v-container>
</template>
<script lang="ts">
import { apiURL } from '@/main'
import { fileCreator, showError } from '@/services/axios'
import searchMessage from '@/components/searchCards/searchMessage.vue'
import _ from 'lodash'
import messageDetail from '@/views/messages/inbox/messageDetail.vue'
import { DTSHeaders } from '@/services/BackendService'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'messages_read',
  data() {
    return {
      items: [],
      dropDown: null,
      hover: false,
      landing: true,
      loading: false,
      loadingAttachment: false,
      loadingUnread: false,
      messageDetail: {},
      messageTitlePreviewLength: 60,
      showDialog: false,
      unreadMessagesNr: 0,
      totalElements: 0
    }
  },
  computed: {
    fields() {
      return <DTSHeaders>[
        {
          title: this.$t('search_message_priority'),
          value: 'priority',
          isReduced: false,
          sortable: true,
          align: 'left'
        },
        {
          title: this.$t('search_message_sender'),
          value: 'senderFullName',
          isReduced: false,
          sortable: true,
          align: 'left'
        },
        {
          title: this.$t('search_message_receivedDate'),
          value: 'sent',
          isReduced: false,
          sortable: true,
          align: 'left'
        },
        {
          title: this.$t('search_message_subject'),
          value: 'subject',
          isReduced: false,
          sortable: true,
          align: 'left'
        },
        {
          title: this.$t('search_message_attachments'),
          value: 'attachments',
          isReduced: false,
          sortable: true,
          align: 'left'
        },
        {
          title: this.$t('search_message_channel'),
          value: 'channel',
          isReduced: false,
          sortable: true,
          align: 'left'
        },
        {
          title: this.$t('search_message_tags'),
          value: 'tags',
          isReduced: true,
          sortable: true,
          align: 'left'
        },
        {
          title: this.$t('search_message_read'),
          value: 'read',
          isReduced: true,
          sortable: true,
          align: 'left'
        },
        {
          title: this.$t('search_message_from'),
          value: 'from',
          isReduced: true,
          sortable: true,
          align: 'left'
        }
      ].filter((el) => !this.$store.state.SearchMessageStore.reduced || !el.isReduced)
    },
    unreadMessagesBadge() {
      return `${this.unreadMessagesNr} ${this.$t('search_message_unread_nr')}`
    },
    query() {
      return {
        senderFullName: this.$store.state.SearchMessageStore.senderFullName,
        messageTitle: this.$store.state.SearchMessageStore.messageTitle,
        recipientFullName: this.$store.state.SearchMessageStore.recipientFullName,
        deliveryStatus: this.$store.state.SearchMessageStore.deliveryStatus,
        attachments: this.$store.state.SearchMessageStore.attachments,
        read: this.$store.state.SearchMessageStore.read,
        messageType: this.$store.state.SearchMessageStore.messageType,
        creationDateFrom: this.$store.state.SearchMessageStore.creationDateFrom,
        creationDateTo: this.$store.state.SearchMessageStore.creationDateTo,
        senderId: this.$store.state.SearchMessageStore.senderId,
        sendingChannel: this.$store.state.SearchMessageStore.sendingChannel,
        reduced: this.$store.state.SearchMessageStore.reduced,
        tags: this.$store.state.SearchMessageStore.tags,
        ...this.getJavaPageOptions({ sort: ['priority,desc', 'sent,desc'] })
      }
    }
  },
  components: {
    searchMessage,
    messageDetail
  },
  methods: {
    openDialog() {
      this.showDialog = true
    },
    closeDialog() {
      this.showDialog = false
    },
    handleClickOnRow(message) {
      if (this.loadingAttachment) return
      this.messageDetail = message
      this.openDialog()
    },
    search() {
      this.landing = false
      this.$router
        .push({
          path: this.$route.path,
          query: this.query
        })
        .catch((e) => {
          // route duplicated
          this.load()
        })
    },
    async downloadAttachment(messageId, attachmentIds, filenames) {
      this.loadingAttachment = true
      try {
        // let attachmentId = item.attachmentIds.toString() // todo implement multiple attachments
        const result = await this.axios.get(apiURL + `/messages/${messageId}/attachments/${attachmentIds}`, {
          headers: {},
          responseType: 'blob'
        })
        fileCreator(result, filenames)
      } catch (e) {
        showError(e)
        let responseObj = e.response.data.text()
        showError({ response: { data: JSON.parse(responseObj) } })
      } finally {
        this.loadingAttachment = false
      }
    },
    async load() {
      /* backend binding */
      if (this.landing) return
      this.loading = true
      try {
        this.items = []
        const response = await this.axios.get(
          apiURL + '/messages/search',
          {
            params: _.omit(this.query, ['reduced'])
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        const items = await response.data.content
        this.items = Array.isArray(items) ? items : []
        this.totalElements = this.checkPage(response.data.totalElements)
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
        this.countUnreadMessages()
      }
    },
    async countUnreadMessages() {
      /* backend binding */
      this.loadingUnread = true
      try {
        const response = await this.axios.get(
          apiURL + '/messages/search',
          {
            params: { ...this.query, page: 0, size: 1, read: false }
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        this.unreadMessagesNr = await response.data.totalElements
      } catch (e) {
        showError(e)
      } finally {
        this.loadingUnread = false
      }
    },
    reset() {
      this.$refs['search-message'].reset()
    }
  },
  watch: {
    dropDown: {
      handler(newValue) {
        if (newValue !== null) {
          alert('ELCA API bulk mark read not implemented yet..') /* todo delete */
          this.dropDown = null
        }
      },
      deep: true
    },
    '$route.query': {
      handler(newValue) {
        this.load()
      },
      deep: true
    }
  },
  mounted() {
    this.$nextTick(async () => {
      if (this.$route.query.size) {
        await this.load()
      }
    })
  }
})
</script>
